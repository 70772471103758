html,
body {
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
.grecaptcha-badge {
    visibility: hidden;
}

.btn-shake:hover {
    animation: none;
}

.btn-shake {
    backface-visibility: hidden;
    will-change: transform;
    animation: shake 3280ms infinite cubic-bezier(.36, .07, .19, .97) both;
    animation-delay: 2s;
}

@media (prefers-reduced-motion) {
    .btn-shake {
        animation: none;
    }
}

@keyframes shake {
    22% {
        transform: translate3d(0, 0, 0);
    }
    5% {
        transform: translate3d(-1px, 0, 0);
    }

    5%, 20% {
        transform: translate3d(2px, 0, 0);
    }

    7%, 12%, 17% {
        transform: translate3d(-4px, 0, 0);
    }

    10%, 15% {
        transform: translate3d(4px, 0, 0);
    }
}
